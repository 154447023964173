@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

.Footer {
  display: flex;
  justify-content: space-around;
  background-color: #001e3c;
  padding-top: 65px;
  padding-bottom: 40px;
  flex-wrap: wrap;
  height: auto;
  opacity: 1;
}
.footer-right-one {
  margin-top: 30px;
}
.Footer h4 {
  font: normal normal bold 35px/1 "Jost";
  text-transform: capitalize;
  margin-bottom: 15px;
  color: white;
  letter-spacing: 0.5px;
}
.Footer p {
  font: normal normal normal 24px/40px "Jost";
  color: white;
  line-height: 30px;
  letter-spacing: 0.5px;
}
.footer-middle li {
  list-style: none;
}
.footer-middle li a {
  list-style: none;
  color: white;
  font: normal normal normal 24px/40px "Jost";
  text-decoration: none;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 40px;
  margin-left: -30px;
  letter-spacing: 0.5px;
}
.footer-middle li a:hover {
  color: #f75023;
}
.footer-right img {
  width: 35px;
  margin-right: 10px;
}
.facebook:hover {
  background-color: #0052d4;
  padding: 5px;
  border-radius: 5px;
  transform: scale(1.2);
}
.instagram:hover {
  background-color: #833ab4;
  padding: 5px;
  border-radius: 5px;
  transform: scale(1.2);
}
.twitter:hover {
  background-color: #0052d4;
  padding: 5px;
  border-radius: 5px;
  transform: scale(1.2);
}
.linkedin:hover {
  background-color: #0052d4;
  padding: 5px;
  transform: scale(1.2);
  border-radius: 5px;
}

/*  Media Querry */

@media only screen and (max-width: 760px) {
  .Footer {
    text-align: center;
  }
  .Footer h4 {
    font: normal normal bold 28px/1 "Jost";
  }
  .Footer p {
    font: normal normal normal 18px/30px "Jost";
  }
  .footer-left {
    margin-bottom: 20px;
  }
  .footer-right {
    margin-top: 20px;
  }
}
