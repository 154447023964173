@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");
.about {
  min-height: 100vh;
  background: url(../Assets/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* padding-top: 6rem; */
}
.about-head {
  text-align: center;
  padding-top: 6rem;
}
.about-head h3 {
  font: normal bold 40px/1 "Jost";
  margin-bottom: 45px;
  letter-spacing: 0.5px;
}
.about-head p {
  font: normal normal 25px/40px "Jost";
  letter-spacing: 0.5px;
  margin-bottom: 5rem;
}
.home-service-card {
  display: flex;
  flex-wrap: wrap;
  border: 2px solid #a09dab;
  padding: 30px;
  margin: 6rem 2rem;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
}
.card-one,
.card-two,
.card-three,
.card-four {
  width: 300px;
  height: max-content;
  border: 2px solid #e7e7e7;
  border-radius: 15px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}
.card-one:hover,
.card-two:hover,
.card-three:hover,
.card-four:hover {
  background: #ffffff;
  box-shadow: 18px 0px 87px 0px rgb(10 15 70 / 30%);
  border-radius: 12px;
  transition: 0.8s all ease;
}
.about ul {
  list-style: upper-roman;
  display: flex;
  justify-content: center;
  gap: 100px;
}
.about li {
  font: normal normal 24px/1 "Jost";
  margin-bottom: 5rem;
}
.app-logo {
  font-size: 120px;
  color: black;
  border-radius: 40px;
  background-color: white;
  padding: 17px;
}
.service-card-title {
  font: normal normal bold 34px "Jost";
  margin-top: 40px;
}
.service-card-content {
  font: normal normal 24px/40px "Jost";
  margin-top: 20px;
}

.quality-team {
  width: 100%;
  height: auto;
  margin-top: 2rem;
  padding-bottom: 3rem;
}
.quality-team img {
  width: 500px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}
.quality-team {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: center;
  gap: 15px;
}
.quality-team h3 {
  font: normal normal bold 40px/1 "Jost";
  letter-spacing: 0.5px;
  margin-bottom: 3rem;
  text-align: center;
}
.quality-team p {
  font: normal normal normal 27px/40px "Jost";
  margin-top: 1rem;
  text-align: center;
  padding: 5px;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .card-one,
  .card-two,
  .card-three,
  .card-four {
    margin-bottom: 1rem;
    padding: 10px;
    width: 100%;
  }
  .quality-team {
    flex-wrap: wrap;
  }
  .quality-team img {
    width: 100%;
  }
  .about h3 {
    font: normal normal bold 25px/35px "Jost";
  }
  .quality-team p {
    font: normal normal normal 18px/30px "Jost";
  }
  .home-service-card {
    margin-top: 3rem;
  }
  .about-head p {
    font: normal normal 18px/35px "Jost";
  }
  .service-card-content {
    font: normal normal 18px/35px "Jost";
  }
  .about-head {
    margin-top: 2rem;
  }
}
