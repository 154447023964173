@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

.contact-map iframe {
  width: 500px;
  height: 400px;
}
.contact {
  display: flex;
  justify-content: center;
  gap: 8rem;
  margin-bottom: 4rem;
  align-items: center;
  flex-wrap: wrap;
  height: 66vh;
  padding-top: 5rem;
  background: url(../Assets/backdrop.png);
  background-size: 1300px;
  background-repeat: no-repeat;
  background-position: 950px;
  z-index: -1111;
}
.contact-left-flex h3 {
  font: normal normal bold 42px/1 "Jost";
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.contact-right-flex h3 {
  font: normal normal bold 42px/1 "Jost";
  letter-spacing: 0.5px;
  margin-bottom: 50px;
}
.contact-right-flex h4 {
  font: normal normal bold 30px/1 "Jost";
  margin-bottom: 20px;
}
.contact-right-flex p {
  font: normal normal 25px/1 "Jost";
}
.contact-section {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 760px) {
  .contact-map iframe {
    width: 100%;
  }
  .contact {
    text-align: center;
    gap: 4rem;
  }
  .contact h3 {
    font: normal normal bold 27px/1 "Jost";
  }
  .contact h4 {
    font: normal normal bold 20px/1 "Jost";
  }
  .contact p {
    font: normal normal 16px/25px "Jost";
  }
}
