@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");
.Navbar {
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001e3c;
  /* position: fixed;
  width: 100%;
  z-index: 11111; */
}

.nav-logo {
  font: normal normal bold 34px "Jost";
  letter-spacing: 2px;
  margin-left: 20px;
  text-transform: uppercase;
  color: #f75023;
}

.Navbar > .nav-items > a {
  font: normal normal bold 24px/1 "Jost";
  text-decoration: none;
  margin: 20px;
  position: relative;
  letter-spacing: 2px;
  cursor: pointer;
  color: whitesmoke;
}

.Navbar > .nav-items {
  margin-right: 30px;
}
.Navbar > .nav-items > a:hover {
  opacity: 1;
  color: #f75023;
  transition: all 0.5s ease-in-out;
}
.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  transition: all 0.45s;
}
.Navbar > .nav-items > a:hover::before {
  width: 100%;
}
.Navbar > .nav-toggle {
  display: none;
}

/* Media Querry */

@media (max-width: 960px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    background: black;
    border-radius: 15px;
    width: 65%;
    height: 100%;
    color: white;
    transform: translateX(-100%);
    transition: all 0.45s;
    gap: 2rem;
  }
  .nav-bottom {
    display: none;
  }
  .nav-logo-image {
    background-color: white;
    border-radius: 20px;
  }
  .Navbar {
    background-color: black;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
  }
  .Navbar > .nav-items > a {
    color: white;
    font: normal normal 18px/1 "Jost";
  }
  .Navbar > .nav-items.open {
    transform: translateX(0);
  }
  .Navbar > .nav-toggle {
    display: flex;
    width: 50;
    height: 40px;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.8;
    margin-right: 20px;
  }
  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }
  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateX(8px);
  }
  .nav-toggle.open > .bar {
    transform: translateX(-40px);
    background: transparent;
  }
  .nav-toggle.open > .bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}
