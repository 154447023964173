@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Roboto+Slab:wght@100;200;300;400;500;600;700&display=swap");

.home-main {
  min-height: 100vh;
  background: url(../Assets/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.slide-image img {
  width: 100%;
  height: 55vh;
  object-fit: cover;
  border-bottom-right-radius: 35px;
}
.slogan h2 {
  text-align: center;
  padding-top: 8rem;
  font: normal normal 36px/50px "jost";
  margin-bottom: 4rem;
}
.slogan h3 {
  font: normal normal bold 38px/1 "jost";
  text-align: center;
  color: #f75023;
}
.home-mid-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  margin-top: 12rem;
}

.mid-content-left img {
  object-fit: contain;
  border-radius: 10px;
  max-width: 100%;
  width: 750px;
}

.mid-content-right {
  font: normal normal 27px/50px "Jost";
  text-align: center;

  width: 950px;
}
.mid-content-right p {
  margin-bottom: 5rem;
}
.home-profile {
  display: flex;
  flex-direction: row;
}

.home-mid-content h3 {
  font: normal normal bold 34px/1 "Jost";
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 45px;
  text-align: center;
}

/* Media Querry */

@media only screen and (max-width: 760px) {
  .home-mid-content {
    flex-wrap: wrap;
    flex-direction: row-reverse;
    gap: 40px;
    margin-top: 4rem;
  }
  .slogan h2 {
    font: normal bold 20px/40px "Jost";
    padding-top: 20px;
  }
  .mid-content-left img {
    width: 100%;
  }
  .mid-content-right h3 {
    font: normal bold 25px/40px "Jost";
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mid-content-right p {
    font: normal normal 20px/40px "jost";
  }
}
